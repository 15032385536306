@import "./variables.scss";

.doc-md-content {
  min-height: max(calc(100vh - 56px), 200px);

  h1 {
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .breadcrumbs + .wrapper-alert-inline {
    margin-top: 0;
  }

  .wrapper-alert-inline:empty {
    display: none;
  }

  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  align-content: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  gap: 16px;

   > * {
    margin: 0 !important;
    -webkit-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    align-self: auto;
   }

   > .wrapper-md-editor {
    -webkit-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: auto;
    align-self: auto;

    position: relative;

    .md-editor {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .cm-content {
        white-space: normal;
        font-size: 14px;
        line-height: 1.4; 
      }

      .md-editor-preview {
        white-space: normal;
        font-size: 14px;
        line-height: 1.4;  
        
        >div {
          overflow: hidden;
          width: 100%;
          margin: 0;
          padding: 0;
        }
    
        * {
          overflow-wrap: break-word;
          margin: 16px 0;
    
          &:first-child {
            margin-top: 0;
          }
    
          &:last-child {
            margin-bottom: 0;
          }
        }
    
        h1,
        h2,
        h3,
        h4,
        h5 {
          overflow-wrap: break-word;
          line-height: 1.4;
          font-weight: 600;
    
          &:first-child {
            margin-top: 0;
          }
    
          &:last-child {
            margin-bottom: 0;
          }
        }
    
        h1 {
          font-size: 24px;
        }
    
        h2 {
          font-size: 22px;
        }
    
        h3 {
          font-size: 20px;
        }
    
        h4 {
          font-size: 18px;
        }
    
        h5 {
          font-size: 16px;
        }
    
        a,
        .link {
          color: #0f62fe;
          text-decoration: underline;
    
          &:hover {
            text-decoration: none;
          }
        }
    
        ul,
        ol {
          display: block;
          list-style: disc outside none;
          margin: 16px 0;
          padding: 0 0 0 40px;
    
          &:first-child {
            margin-top: 0;
          }
    
          &:last-child {
            margin-bottom: 0;
          }
        }
    
        ol {
          list-style-type: decimal;
        }
    
        li {
          display: list-item;
          margin: 8px 0;
    
          &:first-child {
            margin-top: 0;
          }
    
          &:last-child {
            margin-bottom: 0;
          }
        }
    
        ul ul,
        ol ul {
          list-style-type: circle;
        }
    
        ol ol,
        ul ol {
          list-style-type: lower-latin;
        }
    
        blockquote {
          padding: 16px;
          border-radius: 4px;
          background: $gray-100;
          p {
            &:first-child {
              &::before {
                content: '\201C';
              }
            }
            &:last-child {
              &::after {
                content: '\201D';
              }
            }
          }
        }
    
        pre {
          padding: 16px;
          border-radius: 4px;
          background: $gray-100;
        }
    
        img {
          display: inline-block;
          vertical-align: top;
          width: auto;
          max-width: 100%;
          height: auto;
        }
    
        hr {
          border: 0 none;
          background: rgba($black, 0.25);
          height: 1px;
          width: 100%;
          opacity: 1;
        }
    
        .responsive-table {
          display: block;
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }
    
        table {
          width: 100%;
          max-width: 100%;
          border: 1px solid rgba($black, 0.25);
          display: table;
          > thead,
          > tbody,
          > tfoot {
            background: none;
          }
        }
        table > thead > tr > th,
        table > tbody > tr > th,
        table > tfoot > tr > th,
        table > thead > tr > td,
        table > tbody > tr > td,
        table > tfoot > tr > td {
          display: table-cell;
          padding: 8px;
          line-height: 1.4;
          vertical-align: top;
          border-top: 1px solid rgba($black, 0.25);
        }
        table > thead > tr > th {
          vertical-align: bottom;
          border: 0 none;
          background: $gray-100;
          &:last-child{
            border-bottom: 1px solid rgba($black, 0.25);
          }
        }
    
        button,
        input,
        optgroup,
        textarea {
          appearance: auto;
        }
    
      }
    }
   }
}