@import "./variables.scss";

.btn {
  display: inline-block;
  vertical-align: top;
  transition: background-color $trs ease;
  will-change: background-color;
  padding: 7px 15px;
  border-radius: $brs;
  font-weight: $bold;
  color: $black;
  border: 1px solid $gray-200;
  background-color: $white;
  font-size: 14px;
  line-height: 20px;
  width: auto;
  max-width: 100%;
  text-align: center;
  outline: 0 none !important;

  > span {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
  }

  .loader-wrapper {
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
  }

  &:hover {
    background-color: $gray-100;
  }

  &:active,
  &:focus-visible {
    border: 1px solid $gray-200;
    box-shadow: 0px 0px 0px 2px rgba($black, 0.2);
    background-color: $white;
    outline: none;
  }

  &:disabled {
    background-color: $white;
    box-shadow: none;
    color: $black;
    cursor: not-allowed !important;
    pointer-events: none !important;
    opacity: 0.5;
  }

  &.gray {
    color: $gray-700;
  }

  &.primary {
    padding: 8px 16px;
    border: none;
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: $blue;
    }

    &:active,
    &:focus-visible {
      box-shadow: 0px 0px 0px 2px rgba($black, 0.2);
      background-color: $blue;
      outline: none;
    }

    &:disabled {
      background-color: rgba($black, 0.25);
      box-shadow: none;
      color: $white;
      cursor: not-allowed !important;
      pointer-events: none !important;
    }
  }

  &.link {
    padding: 8px 16px;
    color: $black;
    border: none;
    background-color: transparent;

    &:hover {
      > span {
        opacity: 0.75;
      }

      background-color: transparent;
    }

    &:active,
    &:focus-visible {
      box-shadow: 0px 0px 0px 2px rgba($black, 0.2);
      background-color: rgba(White, 0.0005);
      outline: none;
    }

    &.disabled,
    &:disabled {
      opacity: 0.5;
      background-color: transparent;
      box-shadow: none;
      color: $black;
      cursor: not-allowed !important;
      pointer-events: none !important;

      > span {
        opacity: 1;
      }
    }
  }

  &.plus {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/plus.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.trash-bin {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/trashbin.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.drop {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/drop.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.pencil {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/pencil.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.history {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/history.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.chain {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/chain.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.unchain {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/unchain.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.union {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/union.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.download {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/download.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.refresh {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/refresh.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.tooltip-button {
    > span {
      display: inline-block;
      vertical-align: top;
      height: 20px;
      width: 20px;
      padding: 0;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/tooltip-button.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.stop {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/stop.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.restart {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/restart.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.close {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/x.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.import {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/import.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.play-circle {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/play-circle.svg") no-repeat scroll 0 -16px;
      }
    }
  }

  &.search-btn {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/search-btn.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.action {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/action.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.actions {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 24px 0 0;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/actions.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.check {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 18px 0 0;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/check_mark.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.connect {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/connect.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.plus-circle {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/plus-circle.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.arrow_left {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/arrow_left_black.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.arrow_right {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 24px 0 0;

      &::after {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/arrow_left_black.svg") no-repeat scroll 0 0;
        transform: rotate(180deg);
      }
    }
  }

  &.arrow_up {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/arrow_left_black.svg") no-repeat scroll 0 0;
        transform: rotate(90deg);
      }
    }
  }

  &.arrow_down {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/arrow_left_black.svg") no-repeat scroll 0 0;
        transform: rotate(-90deg);
      }
    }
  }

  &.passwordReset {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;
      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/passwordReset.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.tables {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;
      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/tables.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.lock {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;
      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/lock.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.index {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;
      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/index.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.unlock {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;
      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/unlock.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.rebuild {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;
      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/rebuild.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.information {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;
      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/info.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.performance-test {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;
      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/performance-test.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.duplicate {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;
      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/duplicate.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.check-table {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;
      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/check-table.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.transfer-item-right {
    padding: 10px 10px;
    width: 42px;
    height: 42px;
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 16px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/transfer-all.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.transfer-item-left {
    padding: 10px 10px;
    width: 42px;
    height: 42px;
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 16px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0;
        background: transparent url("../svg/transfer-all.svg") no-repeat scroll 0 -16px;
      }
    }
  }

  &.transfer-items-right {
    padding: 10px 10px;
    width: 42px;
    height: 42px;
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 16px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0;
        background: transparent url("../svg/transfer-all.svg") no-repeat scroll 0 -32px;
      }
    }
  }

  &.transfer-items-left {
    padding: 10px 10px;
    width: 42px;
    height: 42px;
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 16px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0;
        background: transparent url("../svg/transfer-all.svg") no-repeat scroll 0 -48px;
      }
    }
  }

  &.estimate-space {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/estimate-space.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.binoculars {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/binoculars.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.unable-list {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/unable-list.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.clear-circle {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/toggle-clear.svg") no-repeat scroll 0 -16px;
      }
    }
  }

  &.disable-circle {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/aborted.svg") no-repeat scroll 0 0px;
      }
    }
  }

  &.ok-circle {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/ok-circle.svg") no-repeat scroll 0 0px;
      }
    }
  }

  &.setup {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/setup.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.relocate {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/relocate.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.import-asset {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/arrowUp.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.success {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/alert_error_success.svg") no-repeat scroll 50% 50%;
      }
    }
  }

  &.download-template {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/arrowUp.svg") no-repeat scroll 0 0;
        transform: rotate(180deg);
      }
    }
  }

  &.error {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/alert_error_icon.svg") no-repeat scroll 50% 50%;
      }
    }
  }

  &.scenario {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/scenario.svg") no-repeat scroll 0 0px;
      }
    }
  }

  &.spinner {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        display: inline-block;
        border: 2px solid $black;
        border-radius: 50%;
        border-top-color: $white;
        animation: spin 1s ease-in-out infinite;
        -webkit-animation: spin 1s ease-in-out infinite;
      }
    }
    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }

  &.save {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/save-icon.svg") no-repeat scroll 0 -16px;
      }
    }
  }

  &.save:disabled {
    > span {
      &::before {
        background: transparent url("../svg/save-icon.svg") no-repeat scroll 0 0;
      }
    }
  }
  &.copy {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/copy.svg") no-repeat scroll 50% 50%;
      }
    }
  }

  &.rollback {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/rollback.svg") no-repeat scroll 50% 50%;
      }
    }
  }

  &.apply {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/apply.svg") no-repeat scroll 50% 50%;
      }
    }
  }

  &.balance {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/balance.svg") no-repeat scroll 50% 50%;
      }
    }
  }

  &.dash {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/dash.svg") no-repeat scroll 50% 50%;
      }
    }
  }

  &.compact {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/compact.svg") no-repeat scroll 50% 50%;
      }
    }
  }

  &.circle-arrow-down {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/circle-arrow-down.svg") no-repeat scroll 50% 50%;
      }
    }
  }

  &.move {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/move.svg") no-repeat scroll 50% 50%;
      }
    }
  }

  &.flush {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/flush.svg") no-repeat scroll 50% 50%;
      }
    }
  }

  &.compare {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/compare.svg") no-repeat scroll 50% 50%;
      }
    }
  }
  &.key {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/key.svg") no-repeat scroll 50% 50%;
      }
    }
  }
  &.paused {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -9px 0 0;
        padding: 0;
        background: transparent url("../svg/pause.svg") no-repeat scroll 0 0;
      }
    }
  }
  &.swap-rebalance {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -9px 0 0;
        padding: 0;
        background: transparent url("../svg/compare.svg") no-repeat scroll 0 0;
        transform: scaleX(-1);
      }
    }
  }
  &.backup {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -9px 0 0;
        padding: 0;
        background: transparent url("../svg/backup.svg") no-repeat scroll 0 0;
      }
    }
  }
  &.info-q {
    > span {
      display: inline-block;
      vertical-align: top;
      width: auto;
      min-height: 16px;
      max-width: 100%;
      position: relative;
      padding: 0 0 0 24px;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/info-q.svg") no-repeat scroll 0 0;
      }
    }
  }

  &.disable-document-action-btn {
    height: 32px;
    width: 32px;
    padding: 8px;
    display: inline-block;
    border: 0 none;
    background: none;

    > span {
      display: inline-block;
      width: unset;
      min-width: 16px;
      min-height: 16px;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/play-circle.svg") no-repeat scroll 0 -16px;
      }
    }
  }

  &.enable-document-action-btn {
    height: 32px;
    width: 32px;
    padding: 8px;
    display: inline-block;
    border: 0 none;
    background: none;

    > span {
      display: inline-block;
      width: unset;
      min-width: 16px;
      min-height: 16px;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -8px 0 0;
        padding: 0;
        background: transparent url("../svg/stop.svg") no-repeat scroll 0 0;
      }
    }
  }
}
