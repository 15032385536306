@import './variables.scss';

.wrapper-admin-list {
  .actions-btn-list {
    & > .enable-document {
      height: 32px;
      width: 16px;
      margin: 0 8px 0 0;
      padding: 0;
      position: relative;
      display: inline-block;
      vertical-align: top;
      &::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        margin: -5px 0 0 -5px;
        background: $green-600;
        position: absolute;
      }
      &.disabled {
        &::before {
          background: $red-600;
        }
      }
    }

    & > .btn {
      height: 32px;
      width: 32px;
      padding: 8px;
      display: inline-block;
      vertical-align: top;
      margin: 0 4px 0 0;

      &:hover {
        opacity: 0.5;
      }

      > span {
        display: block;
        width: 16px;
        height: 16px;
        margin: 0;
        padding: 0;
        overflow: visible;
      }

      &.information {
        border: 0 none;
        background: none;
        padding: 7.5px;
    
        > span {
          display: inline-block;
          width: unset;
          min-width: 17px;
          min-height: 17px;
          position: relative;
    
          &::before {
            content: "";
            display: block;
            width: 17px;
            height: 17px;
            position: absolute;
            top: 50%;
            left: 0;
            margin: -8.5px 0 0;
            padding: 0;
            background-size: 17px 34px;
          }
        }
      }

      &.trash-bin,
      &.pencil {
        border: 0 none;
        background: none;
        padding: 8px;
      }

      &.isLoading {
        border: 0 none;
        background: none;
        opacity: 1;
        padding: 6px;
        > span {
          position: relative;
          width: 20px;
          height: 20px;
          .loader-wrapper {
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -10px 0 0 -10px;
          }
        }
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}

a.table-link {
  color: $active-link;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.wrapper-admin-list.show-info {
  & > .table-loader {
    width: calc(100% - 580px);
    min-width: calc(100% - 580px);
    max-width: calc(100% - 580px);
    padding-right: 10px;
  }
}

.table-loader {
  & > {
    .table {
      thead {
        .wrapper-cell {
          align-self: flex-start;
          white-space: pre-line;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        }
        .number-messages {
          .line-clamp {
            white-space: pre-line;
            display: inline-block;
            vertical-align: top;
            text-align: left;
          }
        }
      }
      .create {
        width: 200px !important;
        max-width: 200px !important;
        min-width: 200px !important;
      }
      .status {
        width: 200px !important;
        max-width: 200px !important;
        min-width: 200px !important;
      }
      .info {
        width: 64px !important;
        max-width: 64px !important;
        min-width: 64px !important;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .admin-actions-documents {
        width: 124px !important;
        max-width: 124px !important;
        min-width: 124px !important;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .number-messages {
        width: 220px !important;
        max-width: 220px !important;
        min-width: 220px !important;
      }
      .admin-actions {
        width: 136px !important;
        max-width: 136px !important;
        min-width: 136px !important;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .number-likes,
      .number-dislikes,
      .number-reference,
      .number-messages {
        width: 160px !important;
        max-width: 160px !important;
        min-width: 160px !important;
      }
      .number-likes-s-chat,
      .number-dislikes-s-chat,
      .number-messages-s-chat,
      .number-bugs-s-chat,
      .section {
        width: 200px !important;
        max-width: 200px !important;
        min-width: 200px !important;
      }
      .category {
        width: 200px !important;
        max-width: 200px !important;
        min-width: 200px !important;
      }
      .title,
      .id {
        width: 150px !important;
        max-width: 150px !important;
        min-width: 150px !important;
      }
      .size {
        width: 150px !important;
        max-width: 150px !important;
        min-width: 150px !important;
        text-align: right;
      }
      .parameter,
      .show-reference,
      .show-like-dislike,
      .link {
        width: 150px !important;
        max-width: 150px !important;
        min-width: 150px !important;
      }
      .message {
        .wrapper-cell {
          white-space: pre-line;
          word-wrap: break-word;
          overflow-wrap: break-word;

          a {
            color: #0f62fe;
            text-decoration: underline;
      
            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      // ---

      .publisher {
        width: 120px !important;
        max-width: 120px !important;
        min-width: 120px !important;
      }

      .helpful {
        width: 120px !important;
        max-width: 120px !important;
        min-width: 120px !important;
      }

      // ---

      .empty-column {
        flex: 1 0 auto !important;
      }
    }
  }

  &.info-table {
    .table {
      .wrapper-sticky-pagination {
        min-height: auto;
        .table-responsive {
          height: auto !important;
        }
      }
    }
  }

  &.reference-table {
    margin: 16px 0 0;

    &:empty {
      display: none;
    }

    &:first-child {
      margin-top: 0;;
    }

    .table {
      .wrapper-sticky-pagination {
        min-height: auto;
        .table-responsive {
          height: auto !important;
        }
      }
    }
  }
}
