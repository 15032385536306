.wrapper-pdf-documents {
  .wrapper-admin-list {
    .data-row-info {
      .info-body {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: stretch;

        > div {
          flex: 1 1 auto;
          align-self: stretch;

          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-content: stretch;
          align-items: stretch;

          form {
            flex: 1 1 auto;
            align-self: stretch;

            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-content: stretch;
            align-items: stretch;
            gap: 16px;

            > * {
              margin: 0 !important;
            }

            .admin-actions {
              min-width: 64px !important;
              width: 64px !important;
              max-width: 64px !important;
            }

            .actions-btn-list {
              & > .btn {
                height: 32px;
                width: 32px;
                padding: 8px;
                display: inline-block;
                vertical-align: top;
                margin: 0;
          
                &:hover {
                  opacity: 0.5;
                }
          
                > span {
                  display: block;
                  width: 16px;
                  height: 16px;
                  margin: 0;
                  padding: 0;
                  overflow: visible;
                }

                &.close {
                  border: 0 none;
                  background: none;
                  padding: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}