@import "./variables";

.chat-admin {
  .message-action {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    & > .wrapper-input {
      margin: 0 8px 0 0;
    }
    & > .btn {
      margin-right: 8px;
      min-width: 94px;
    }
    margin-bottom: 10px;
  }
  .message-chat-admin__actions {
    margin-top: 16px;
    & > .btn-flex-group {
      & .btn {
        min-width: auto;
      }
      margin: 16px -4px;
    }
    .btn.btn.isLoading {
      .loader-wrapper {
        margin-right: 0;
      }
    }
    .btn.isLoading > span > span {
      display: none;
    }
    .btn.like {
      background: $green-200;
    }
    .btn.dislike {
      background: $red-200;
    }
  }
}
