@import "./variables.scss";

.loader-wrap {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  z-index: 1060;
  flex-direction: column;
  & > .loader {
    width: 80px;
    height: 80px;
    border: 6px solid $gray;
    border-bottom-color: $blue;
    border-radius: 50%;
    margin-bottom: 10px;
    animation: rotation 3s linear infinite;

    @keyframes rotation {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
