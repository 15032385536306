@import "./variables.scss";

.login {
  width: 100%;
  margin: 0;
  padding: 0;
  h1 {
    margin: 0;
    font-size: 24px;
    line-height: 34px;
    color: $black;
    min-height: 34px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    padding: 0 240px 0 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    > span {
      display: block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.login-logo {
        display: block;
        position: absolute;
        width: 219px;
        height: 25px;
        background: transparent url("../../assets/svg/logo/logo_black.svg") no-repeat scroll 0;
        background-size: 219px 25px;
        margin: 0;
        padding: 0;
        top: 50%;
        right: 0;
        margin: -12.5px 0 0;
      }
    }
  }
  > p {
    display: block;
    width: 100%;
    overflow: hidden;
    margin: 16px 0;
    padding: 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: $active-link;
      cursor: pointer;
      text-decoration: none;
      font-size: 14px;
      line-height: $line-height-base;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .btn {
    width: 100%;
  }
}