@import "./variables.scss";

.header {
  background-color: $black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1200px;
  height: 56px;
  z-index: 1050;

  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    min-height: 56px;
    padding: 0 30px 0 0;


   > div:nth-child(1) {
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
      width: 70px;
      max-width: 70px;
      padding: 10px 24px 10px 10px;
    }
    > div:nth-child(2) {
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
      width: 220px;
      max-width: 220px;
    }
    > div:nth-child(3) {
      order: 0;
      flex: 1 1 auto;
      align-self: auto;
      width: calc(100% - 290px);
      max-width: calc(100% - 290px);
      padding: 0 0 0 30px;
    }
  }

  .header-logo {
    display: block;
    width: 100%;
    position: relative;
    height: 0;
    padding: 0 0 11.2%;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
    > span {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: transparent url("../../assets/svg/logo/logo.svg") no-repeat scroll 0 100%;
      background-size: 100% 100%;
    }
    &:hover {
      opacity: 0.75;
    }
  }

  .header-menu {
    display: block;
    list-style: none;
    padding: 0;
    box-shadow: 0 6px 24px -6px rgba($black, 0.2);
    border: 1px solid $gray-200;
    background-color: $white;
    border-radius: $brs;
    width: 240px;
    position: absolute;
    top: 100%;
    right: 30px;
    z-index: 1060;
    li {
      padding: 8px 24px;
      &:last-child {
        border-top: 1px solid $gray-200;
      }
    }
    .btn.link,
    a {
      display: block;
      overflow: hidden;
      width: 100%;
      cursor: pointer;
      text-decoration: none;
      font-size: 14px;
      line-height: 20px;
      color: $black;
      font-weight: 400;
      margin: 0;
      padding: 0;
      text-align: left;
      border-radius: 0;
      box-shadow: none;
      &:hover {
        color: $active-link;
      }
    }
  }

  .header-profile {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;

    > li {
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
      width: 44px;
      max-width: 44px;
      position: relative;
    }

    .header-avatar {
      display: block;
      cursor: pointer;
      width: 44px;
      height: 40px;
      margin: 0;
      padding: 2px 4px;
      border: 0 none;
      outline: none;
      border-radius: 4px;
      background: none;
      &:hover {
        opacity: 0.75;
      }
      > span {
        display: block;
        width: 36px;
        height: 36px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-radius: 50%;
        background: $gray-700;
        font-weight: 400;
        font-size: 14px;
        line-height: 36px;
        color: $white;
        text-align: center;
        margin: 0 auto;
        text-transform: uppercase;
        pointer-events: none;
      }
    }

    li.active {
      .header-avatar {
        > span {
          background: $primary;
        }
      }
    }
  }

  .burger-btn {
    display: block;
    width: 36px;
    height: 36px;
    margin: 0;
    padding: 0;
    position: relative;
    border: 0 none;
    outline: none;
    border-radius: $brs;
    transition: 0.3s ease transform;
    cursor: pointer;

    .menu_checkbox {
      width: 16px;
      width: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0;

      > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $white;
        margin-left: 0;
        margin-bottom: 3px;
        border-radius: 12px;
        
        &:nth-child(1) {
          margin-top: 2px;
        }
      }
    }

    &.expanded {
      .menu_checkbox {
        transform: translate(-50%, -50%) rotateZ(-90deg);
      }
    }

    &:hover {
      opacity: 0.75;
    }

    &:active,
    &:focus-visible {
      border: 1px solid rgba($white, 0.75);
      box-shadow: 0px 0px 0px 2px rgba($white, 0.2);
    }
  }
}
